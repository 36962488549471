<template>
	<statistics-layout :is-loading="isLoading">
		<div v-if="filteredViewings.length && !isLoading">
			<div v-if="userType === 'repository'" class="consigner-filter">
				<label>{{ translate('consigner') }}s:</label>
				<v-select
					v-model="selectedConsigners"
					:options="consigners"
					:searchable="true"
					:close-on-select="false"
					multiple
				/>
				<small>Results will be limited to only those selected.</small>
			</div>
			<data-table v-if="mq.medium" :is-compact="true" :is-hoverable="false" :is-full-width="false">
				<thead>
					<tr>
						<th>{{ translate('consignerStudy') }}</th>
						<th v-if="userType === 'repository'">{{ translate('consigner') }}</th>
						<th>Viewed By</th>
						<th>Organization</th>
						<th>Date Viewed</th>
						<th class="text-right">X-Ray Duration</th>
						<th class="text-right">Video Duration</th>
						<th v-if="requireEndoscopicDisclosure">Endoscopic Disclosure</th>
						<th>Client</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="viewing in filteredViewings" :key="viewing.viewingId">
						<td>{{ viewing.lotNumber }}</td>
						<td v-if="userType === 'repository'">{{ viewing.consignerName }}</td>
						<td>{{ viewing.userFullName }}</td>
						<td>{{ viewing.organizationName }}</td>
						<td>{{ viewing.viewingDateTime | localizeDate }}</td>
						<td>{{ viewing.xRayDuration }} mins</td>
						<td>{{ viewing.videoDuration }} mins</td>
						<td v-if="requireEndoscopicDisclosure">{{ endoscopicDisclosure(viewing.endoscopyDisclosure) }}</td>
						<td>{{ viewing.clientName }}</td>
					</tr>
				</tbody>
			</data-table>
			<div v-if="!mq.medium">
				<ast-card v-for="viewing in filteredViewings" :key="viewing.viewingId" class="viewing-card">
					<div class="row">
						<div class="col-sm-12 col-md-4">
							<ul>
								<li>
									<label>{{ translate('consignerStudy') }}:</label>
									{{ viewing.lotNumber }}
								</li>
								<li v-if="userType !== 'consigner'">
									<label>Consigner:</label>
									{{ viewing.consignerName }}
								</li>
								<li>
									<label>Date Viewed:</label>
									{{ viewing.viewingDateTime | localizeDate }}
								</li>
							</ul>
						</div>
						<div class="col-sm-12 col-md-4">
							<ul>
								<li>
									<label>Viewed by:</label>
									{{ viewing.userFullName }}
								</li>
								<li>
									<label>Organization:</label>
									{{ viewing.organizationName }}
								</li>
								<li>
									<label>Client:</label>
									{{ viewing.clientName }}
								</li>
							</ul>
						</div>
						<div class="col-sm-12 col-md-4">
							<ul>
								<li>
									<label>X-Ray:</label>
									{{ viewing.xRayDuration }} mins
								</li>
								<li>
									<label>Video:</label>
									{{ viewing.videoDuration }} mins
								</li>
								<li v-if="requireEndoscopicDisclosure">
									<label>Endoscopic Disclosure:</label>
									{{ endoscopicDisclosure(viewing.endoscopyDisclosure) }}
								</li>
							</ul>
						</div>
					</div>
				</ast-card>
			</div>
		</div>
		<p v-if="!filteredViewings.length && !isLoading" class="placeholder">
			No viewing logs found.
		</p>
	</statistics-layout>
</template>

<script>
import StatisticsLayout from '@router/layouts/Statistics'
import AstCard from '@components/Card'
import DataTable from '@components/DataTable'
import api from '@services/api'
export default {
	components: {
		StatisticsLayout,
		AstCard,
		DataTable,
	},
	props: {
		clinicCode: {
			type: String,
			required: true,
		},
		userType: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			viewings: [],
			isLoading: true,
			consigners: [],
			selectedConsigners: [],
      requireEndoscopicDisclosure: false,
		}
	},
	computed: {
		filteredViewings() {
			if (!this.userType === 'repository' || !this.selectedConsigners.length) return this.viewings
			return this.viewings.filter(viewing => this.selectedConsigners.includes(viewing.consignerName))
		},
  },
	watch: {
		'$route.query.saleId'() {
			this.loadViewings()
		},
	},
  async created() {
    const clinicInfo = await api.repository.get(this.clinicCode)
    this.requireEndoscopicDisclosure = clinicInfo.data.requireEndoscopicDisclosure
  },
	mounted() {
		this.loadViewings()
	},
	methods: {
		async loadViewings() {
			this.isLoading = true
			try {
				let params = {
					clinicCode: this.clinicCode,
					saleId: this.$route.query.saleId,
				}
				if (this.userType === 'repository') params.securityToken = this.id
				if (this.userType === 'consigner') params.consignerId = this.id
				if (this.userType === 'user') params.userId = this.id
				if (this.userType === 'client') params.consignerClientId = this.id
				const { data } = await this.$api.statistics.getViewings(params)
				this.viewings = data
				this.consigners = [...new Set(this.viewings.map(viewing => viewing.consignerName))].filter(Boolean).sort()
			} finally {
				this.isLoading = false
			}
		},
    endoscopicDisclosure(ed) {
      return (ed && ed.toUpperCase() !== "NULL" ? ed : 'N/A')
    }
	},
}
</script>

<style lang="scss" scoped>
.consigner-filter {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 0.75em;
	font-size: 0.9em;
	label {
		padding-right: 0.5em;
	}
	.v-select {
		display: block;
		flex-grow: 1;
		min-width: 290px;
		max-width: 500px;
		margin-right: 0.5em;
	}
	small {
		display: block;
		min-width: 300px;
		padding: 0.5em 0;
		opacity: 0.9;
	}
}
</style>
